import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Table from "../../components/Table";
import { PaymentHistoryColumns, RedeemHistoryColumns } from "./data";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/customPaginationComponent/customPagination";
import { listingUsersPaymentHistoryLoading, listingUsersPaymentHistory, listingUsersRedeemHistory, listingUsersRedeemHistoryLoading } from "../../redux/users/actions";
import Loader from "../../components/Loader";

const PaymentOrRedeemHistory = () => {
  const { type, id } = useParams();
  const dispatch = useDispatch();
  const {
    usersList,
    paymentHistoryLoading,
    paymentHistory,
    walletHistoryLoading,
    walletHistory,
  } = useSelector((state: RootState) => state.UsersReducer);

  useEffect(() => {
    type === "history" &&  dispatch(listingUsersPaymentHistory({page:0,limit:50, id:id}))
    type === "redeem" && dispatch(listingUsersRedeemHistory({page:0,limit:50, id:id}))
  },[])
  useEffect(() => {
    type === "history" && dispatch(listingUsersPaymentHistoryLoading())
    type === "redeem" && dispatch(listingUsersRedeemHistoryLoading())
  })

  console.log("the res",walletHistory,
    paymentHistory, )
    const redeemcolumns =[
      {
        Header: "ID",
        accessor: "id",
        sort: false,
      },
      {
        Header: "Amount",
        accessor: "amount",
        sort: false,
      },
      {
        Header: "Updated Date",
        accessor: "updatedAt",
        sort: false,
      },
    ]
  const columns = [
    
    {
      Header: " Hotel Name",
      accessor: "hotelName",
      sort: false,
    },
    {
      Header: "Outlet Name",
      accessor: "outletName",
      sort: false,
    },
    {
      Header: "Amount",
      accessor: "amount",
      sort: false,
    },
    {
      Header: "Remarks",
      accessor: "remarks",
      sort: false,
    },
  ];

  return (
    <>
      {type === "history" && 
      <><h1> Payment History</h1><Card>
         {paymentHistoryLoading ? <Loader/> : (<><Table columns={columns} data={PaymentHistoryColumns(paymentHistory)} /><Pagination
          className="pagination-bar"
          currentPage={1}
          totalCount={paymentHistory?.totalCount}
          pageSize={50}
          onPageChange={(page: any) => {
            // console.log("the page ", page)
            // setCurrentPage(page)
            // navigate(`/users/list/${page}/${limit}/${name}/${userStatus === "all" ? "REGISTERED" : userStatus}`);
            (dispatch(listingUsersPaymentHistory({ page: page, limit: 50, id: id })));
          } } /></>)} 
        </Card></>}
      {type === "redeem" && 
      <>
      <h1>Redeem History</h1>
      <Card>
         {walletHistoryLoading ? <Loader/> : (<><Table columns={redeemcolumns} data={RedeemHistoryColumns(walletHistory)} /><Pagination
            className="pagination-bar"
            currentPage={1}
            totalCount={walletHistory?.totalCount}
            pageSize={50}
            onPageChange={(page: any) => {
              // console.log("the page ", page)
              // setCurrentPage(page)
              // navigate(`/users/list/${page}/${limit}/${name}/${userStatus === "all" ? "REGISTERED" : userStatus}`);
              dispatch(listingUsersRedeemHistory({ page: 0, limit: 50, id: id }));
            } } /></>)} 
        </Card>
      </>
      }
    </>
  );
};

export default PaymentOrRedeemHistory;
