import moment from "moment";
import Table from "../../components/Table";
import Pagination from "../../components/customPaginationComponent/customPagination";
import { Card } from "react-bootstrap";
import classNames from "classnames";

const PaidListPage = ({
  paidList,
  WalletPadiListTableColumns,
  currentPage,
  setCurrentPage,
  navigate,
  limit,
  state,
  tab,
  dispatch,
  listPaid
}: any) => {

// paid list columsn
  const columns = [
    {
      Header: "User",
      accessor: "userName",
      sort:false,
    },
    {
      Header: "Bank Name",
      accessor: "bankName",
      sort:false,
    },
    {
      Header: "Bank Account No",
      accessor: "bankAccNo",
      sort: false,
    },
    {
      Header: "Contact Number",
      accessor: "contactNumber",
      sort:false,
    },
    {
      Header: "Wallet Balance",
      accessor: "walletBalance",
      sort: false,
    },
    {
      Header: "Amount",
      accessor: "amount",
      sort: false,
    },
      {
          Header: "Wallets History",
          accessor: "wallet",
          sort: false,
          Cell: ({ cell }: any) => (
            <>
              <span>
                <a href={`/users/payment/history/${cell.row.original.id}`}>
                  Payment history
                </a>
              </span>{" "}
              &nbsp;
              <span>
                <a href={`/users/payment/redeem/${cell.row.original.id}`}>
                  Redeem hisory
                </a>
              </span>
            </>
          ),
        },
        {
          Header: "Chat ",
          accessor: "chat",
          Cell: ({ cell }: any) => (
            <>
              <span
                style={{ fontSize: "large", color: "green", cursor: "pointer" }}
              >
                <i
                  onClick={() =>
                    window.open(
                      `https://wa.me/${cell.row.original.contactNumber}`,
                      "_blank"
                    )
                  }
                  className={classNames("fab", "fa-whatsapp", "mdi-account")}
                ></i>
              </span>
              <span
                style={{
                  fontSize: "large",
                  color: "green",
                  cursor: "pointer",
                  paddingLeft: "5px",
                }}
              >
                <i onClick={() => {
                  localStorage.setItem("selectedUser",JSON.stringify(cell.row.original));
                  navigate("/chat");
                  }} className="bi bi-chat"></i>
              </span>
            </>
          ),
        },
    {
      Header: "Approved",
      accessor: "isApproved",
      sort: false,
    },
  ];
  return (
    <>
    <Card>
      <Card.Body>
      <Table
        columns={columns}
        data={WalletPadiListTableColumns(paidList)}
        isSortable={true}
        pagination={false}
      />
      </Card.Body>
    </Card>
      
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={paidList?.totalCount}
        pageSize={10}
        onPageChange={(page: any) => {
          setCurrentPage(page);
          navigate(`/wallet-request/${page}/${limit}/${tab}`);
          dispatch(
            listPaid({
              page: currentPage,
              limit: limit,
              startDate: moment((state[0].startDate)).startOf('day').utc().format(),
              endDate: moment((state[0].endDate)).endOf('day').utc().format()

            })
          );
        }}
      />
    </>
  );
};

export default PaidListPage;
