/* eslint-disable react-hooks/exhaustive-deps */
import { DateRangePicker } from "react-date-range";
import "moment-timezone";
import moment from "moment";
import { addDays } from "date-fns";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Modal,
  Nav,
  OverlayTrigger,
  Tab,
} from "react-bootstrap";
import Table from "../../components/Table";
import CommonSearchBar from "../../layouts/SearchBar";
import CreateGroup from "../groups/CreateGroup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  deleteSlots,
  listingSlots,
  listSlotsLoading,
  listingSlotsByUserId,
} from "../../redux/slots/actions";
import { listingHotels } from "../../redux/hotels/actions";
import { listingOutlets } from "../../redux/outlets/actions";
import { SlotsTableColumns } from "./data";
import { editSelectedGroups } from "../../redux/actions";
import timer from "../../assets/images/timer.png";
import confirmed from "../../assets/images/user-check.png";
import release from "../../assets/images/new-release.png";
import Tooltip from "react-bootstrap/Tooltip";
import { Controller, useForm } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { OutletsActionTypes } from "../../redux/outlets/constants";
import Pagination from "../../components/customPaginationComponent/customPagination";
import AddSlotPageModal from "./addSlotPageModal";
import Loader from "../../components/Loader";

const SlotListingPage = () => {
  useEffect(() => {
    // setState([])
    dispatch(listingHotels({ page: 0, limit: 10 }));
  }, []);
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip-upcoming" {...props}>
      CONFIRMED + WAITING / RELEASED NO.
    </Tooltip>
  );
  const [responsiveModal, setResponsiveModal] = useState(false);
  const [responsiveModalDate, setResponsiveModalDate] = useState(false);
  const [responsiveModalForAddSlots, setResponsiveModalForAddSlots] =
    useState(false);
  // Get today's date
  let currentDate = new Date();

  const [state, setState] = useState<any>([
    {
      startDate: new Date(currentDate).setMonth(currentDate.getMonth() - 6),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const toggleResponsiveModal = () => setResponsiveModal(!responsiveModal);

  const toggleResponsiveModalDate = () =>
    setResponsiveModalDate(!responsiveModalDate);

  const toggleResponsiveModalForAddSlots = () =>
    setResponsiveModalForAddSlots(!responsiveModalForAddSlots);

  const dispatch = useDispatch();
  const { slotsList, positions, slotsloading } = useSelector(
    (state: RootState) => state.SlotsReducer
  );
  const navigate = useNavigate();
  const { name, hotel, outlet, page, limit } = useParams();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const { hotelsList } = useSelector((state: RootState) => state.HotelsReducer);
  const { outletsList } = useSelector(
    (state: RootState) => state.OutletsReducer
  );

  const deleteParam = query.get("delete");
  const editParam = query.get("edit");
  const addParam = query.get("add");
  const startDate = query.get("startDate");
  const endDate = query.get("endDate");
  const userID = query.get("userId");
  const type = query.get("type");
  console.log("th sloset", slotsList);
  useEffect(() => {
    // dispatch(
    //   listingSlots({
    //     page: currentPage,
    //     limit: 10,
    //     startDate: startDate,
    //     endDate: endDate,
    //   })
    // );
    if (userID && type) {
      dispatch(listSlotsLoading());
      dispatch(
        listingSlotsByUserId({
          page: currentPage,
          limit: 50,
          id: userID,
          type: type,
        })
      );
    }
    if (editParam) {
      dispatch(editSelectedGroups(hotel));
    }
  }, [page, limit]);

  useEffect(() => {
    if (userID && type) {
      dispatch(listSlotsLoading());
      dispatch(
        listingSlotsByUserId({
          page: currentPage,
          limit: 50,
          id: userID,
          type: type,
        })
      );
    } else {
      navigate(
        `/slots/list/${page}/${limit}/${name}/${hotel}/${outlet}?startDate=${moment(
          state[0].startDate
        )
          .startOf("day")
          .format("YYYY-MM-DDThh:mm:ss.000[Z]")}&endDate=${moment(
          state[0].endDate
        )
          .endOf("day")
          .format("YYYY-MM-DDThh:mm:ss.000[Z]")}`
      );
      dispatch(listSlotsLoading());
      dispatch(
        listingSlots({
          page: currentPage,
          limit: 50,
          startDate: moment(state[0].startDate).startOf("day").utc().format(),
          endDate: moment(state[0].endDate).endOf("day").utc().format(),
          hotel: hotel !== "all" ? hotel : "",
          outlet: outlet !== "all" ? outlet : "",
        })
      );
    }
  }, [state]);

  const searchSlots = (e: any) => {
    if (!e.target.value) {
      navigate(
        `/slots/list/${page}/${limit}/all/all/all?startDate=${moment(
          state[0].startDate
        )
          .startOf("day")
          .format("YYYY-MM-DDThh:mm:ss.000[Z]")}&endDate=${moment(
          state[0].endDate
        )
          .endOf("day")
          .add(7, "days")
          .format("YYYY-MM-DDThh:mm:ss.000[Z]")}`
      );
    } else {
      navigate(
        `/slots/list/${page}/${limit}/${
          e.target.value
        }/${hotel}/${outlet}?startDate=${moment(state[0].startDate)
          .startOf("day")
          .format("YYYY-MM-DDThh:mm:ss.000[Z]")}&endDate=${moment(
          state[0].endDate
        )
          .endOf("day")
          .add(7, "days")
          .format("YYYY-MM-DDThh:mm:ss.000[Z]")}`
      );
    }
    dispatch(listSlotsLoading());
    dispatch(
      listingSlots({
        name: e.target.value,
        hotel: hotel !== "all" ? hotel : "",
        outlet: outlet !== "all" ? outlet : "",
        limit: 50,
        page: currentPage,
        startDate: moment(state[0].startDate).startOf("day").utc().format(),
        endDate: moment(state[0].endDate).endOf("day").utc().format(),
      })
    );
  };

  const deleteEvent = (id: any) => {
    console.log("the id", id);
    dispatch(deleteSlots(id));
    query.delete("delete");
    setResponsiveModal(!responsiveModal);
    navigate(-1);
  };
  const addEvent = () => {
    setResponsiveModalForAddSlots(!responsiveModalForAddSlots);
  };

  const searchByName = (name: any, value: any) => {
    if (name === "hotels") {
      // console.log("the hotele serac", value)
      navigate(
        `/slots/list/${page}/${limit}/${name}/${
          value ? value : "all"
        }/${outlet}?startDate=${moment(state[0].startDate)
          .startOf("day")
          .format("YYYY-MM-DDThh:mm:ss.000[Z]")}&endDate=${moment(
          state[0].endDate
        )
          .endOf("day")
          .add(7, "days")
          .format("YYYY-MM-DDThh:mm:ss.000[Z]")}`
      );
      dispatch(listingHotels({ name: value ? value : "" }));
    } else {
      debugger;
      navigate(
        `/slots/list/${page}/${limit}/${name ? name : "all"}/${
          hotel ? hotel : "all"
        }/${value ? value : "all"}?startDate=${moment(state[0].startDate)
          .startOf("day")
          .format("YYYY-MM-DDThh:mm:ss.000[Z]")}&endDate=${moment(
          state[0].endDate
        )
          .endOf("day")
          .add(7, "days")
          .format("YYYY-MM-DDThh:mm:ss.000[Z]")}`
      );
      dispatch(
        listingOutlets(OutletsActionTypes.LIST_OUTLETS, {
          name: value ? value : "",
          hotel: hotel,
        })
      );
    }
  };

  const filterValueChangeByHotelsOrOutlets = (fieldname: any, e: any) => {
    console.log("the name , e", fieldname, e);
    if (fieldname === "hotels") {
      navigate(
        `/slots/list/${page}/${limit}/${name}/${
          e[0]?.value ? e[0]?.value : "all"
        }/${outlet}?startDate=${moment(state[0].startDate)
          .startOf("day")
          .format("YYYY-MM-DDThh:mm:ss.000[Z]")}&endDate=${moment(
          state[0].endDate
        )
          .endOf("day")
          .format("YYYY-MM-DDThh:mm:ss.000[Z]")}`
      );
      dispatch(
        listingOutlets(OutletsActionTypes.LIST_OUTLETS, {
          hotel: e[0]?.value ? e[0]?.value : "",
          page: page,
          limit: 1000,
        })
      );
      dispatch(listSlotsLoading());
      dispatch(
        listingSlots({
          hotel: e[0]?.value ? e[0]?.value : "",
          page: currentPage,
          limit: 50,
          startDate: moment(state[0].startDate).startOf("day").utc().format(),
          endDate: moment(state[0].endDate).endOf("day").utc().format(),
        })
      );
    } else if (fieldname === "outlets") {
      navigate(
        `/slots/list/${page}/${limit}/${name ? name : "all"}/${hotel}/${
          e[0]?.value ? e[0]?.value : "all"
        }?startDate=${moment(state[0].startDate)
          .startOf("day")
          .format("YYYY-MM-DDThh:mm:ss.000[Z]")}&endDate=${moment(
          state[0].endDate
        )
          .endOf("day")
          .format("YYYY-MM-DDThh:mm:ss.000[Z]")}`
      );
      dispatch(
        listingOutlets(OutletsActionTypes.LIST_OUTLETS, {
          hotel: hotel,
          page: page,
          limit: limit,
        })
      );
      dispatch(listSlotsLoading());
      debugger;
      dispatch(
        listingSlots({
          outlet: e[0] ? e[0]?.value : "",
          hotel: hotel,
          page: currentPage,
          limit: 50,
          startDate: moment(state[0].startDate).startOf("day").utc().format(),
          endDate: moment(state[0].endDate).endOf("day").utc().format(),
        })
      );
    }
  };

  const hotelOptionsListForSearch = (data: any) => {
    let hotel: any = [];
    data?.data?.map((list: any, index: any) =>
      hotel.push({
        id: index,
        value: list._id,
        label: list.hotelName ? list.hotelName : " ",
      })
    );
    return hotel;
  };
  const outletOptionsListForSearch = (data: any) => {
    console.log("the data", data, outletsList);
    let hotel: any = [];
    data?.data?.map((list: any, index: any) =>
      hotel.push({
        id: index,
        value: list._id,
        label: list.outletName ? list.outletName : " ",
      })
    );
    return hotel;
  };

  const columns = [
    {
      Header: "Position",
      accessor: "position",
      sort: false,
    },
    {
      Header: "Date",
      accessor: "date",
      sort: false,
    },
    {
      Header: "Hotel",
      accessor: "hotel",
      sort: false,
    },
    {
      Header: "Outlet",
      accessor: "outlet",
      sort: false,
    },
    {
      Header: "Shift Name",
      accessor: "shiftName",
      sort: false,
    },
    {
      Header: "Status",
      accessor: "status",
      sort: false,
    },
    {
      Header: "Requested No",
      accessor: "vacancy",
      sort: false,
    },
    {
      Header: () => (
        <OverlayTrigger
          placement="top"
          // delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={confirmed}
                className="rounded-circle"
                alt=""
                width={"20px"}
                height={"20px"}
              />
              <div style={{ fontSize: "xx-large" }}>+</div>
              <img
                src={timer}
                className="rounded-circle"
                alt=""
                width={"20px"}
                height={"20px"}
              />
              <div style={{ fontSize: "xx-large" }}>/</div>
              <img
                src={release}
                className="rounded-circle"
                alt=""
                width={"30px"}
                height={"30px"}
              />
            </div>
          </div>
        </OverlayTrigger>
      ),
      // Header: "CONFIRMED + WAITING / RELEASED NO.",
      accessor: "confirmedWaitingNo",
      sort: false,
      // Cell:({cell}:any) =>(
      //   <div><img src="../../assets/images/timer.png" alt=""/></div>
      // )
    },
    {
      Header: "PAY/HR",
      accessor: "hourlyPay",
      sort: false,
    },
    {
      Header: "TOTAL EXPECTED PAY",
      accessor: "totalPay",
      sort: false,
    },
    {
      Header: "Action",
      accessor: "action",
      sort: false,
      Cell: ({ cell }: any) => (
        <>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => handleEditSlots(cell.row.original)}
          >
            <i className="bi bi-eye"></i>
          </span>
          &nbsp;&nbsp;
          <span
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteSlot(cell.row.original.id)}
          >
            <i className="bi bi-trash3"></i>
          </span>
        </>
      ),
    },
  ];
  const handleDeleteSlot = async (id: any) => {
    console.log("the id", id);
    await dispatch(deleteSlots(id));
    await dispatch(listSlotsLoading());
    if (userID && type) {
      await dispatch(
        listingSlotsByUserId({
          page: currentPage,
          limit: 50,
          id: userID,
          type: type,
        })
      );
    } else {
      await dispatch(
        listingSlots({
          page: currentPage,
          limit: 50,
          hotel: hotel !== "all" ? hotel : "",
          outlet: outlet !== "all" ? outlet : "",
          startDate: moment(state[0].startDate)
            .startOf("day")
            .format("YYYY-MM-DDThh:mm:ss.000[Z]"),
          endDate: moment(state[0].endDate)
            .endOf("day")
            .format("YYYY-MM-DDThh:mm:ss.000[Z]"),
        })
      );
    }
  };

  const tabContents = () => {
    let i;
    let j;
    const arr = [];
    for (i = 7; i >= 0; i--) {
      arr.push({
        index: i,
        date: moment(new Date()).startOf("day").subtract(i, "days"),
        dateShow: moment(new Date())
          .endOf("day")
          .subtract(i, "days")
          .format("MMM DD"),
      });
    }
    for (j = 1; j <= 7; j++) {
      arr.push({
        index: j,
        date: moment(new Date()).startOf("day").add(j, "days"),
        dateShow: moment(new Date())
          .endOf("day")
          .add(j, "days")
          .format("MMM DD"),
      });
    }
    return arr;
  };

  const handleEditSlots = (cell: any) => {
    navigate(`/slots/list/edit/${cell.id}`);
  };

  const addHotelUsersForAddSlots = (details: any) => {
    if (details.outlet) {
      localStorage.setItem("outlet", JSON.stringify(details.outlet));
      localStorage.setItem("date", JSON.stringify(details.date));
      setResponsiveModalForAddSlots(!responsiveModalForAddSlots);
      navigate(`/slots/list/add`);
    }
  };
  const [currentPage, setCurrentPage] = useState(1);
  return (
    <>
      <AddSlotPageModal
        filterValueChangeByHotelsOrOutlets={filterValueChangeByHotelsOrOutlets}
        setResponsiveModalForAddSlots={setResponsiveModalForAddSlots}
        responsiveModalForAddSlots={responsiveModalForAddSlots}
        toggleResponsiveModalForAddSlots={toggleResponsiveModalForAddSlots}
        addHotelUsersForAddSlots={addHotelUsersForAddSlots}
        hotelsList={
          hotelOptionsListForSearch(hotelsList)
            ? hotelOptionsListForSearch(hotelsList)
            : []
        }
        outletsList={
          outletOptionsListForSearch(outletsList)
            ? outletOptionsListForSearch(outletsList)
            : []
        }
      />

      <Modal show={responsiveModalDate} onHide={toggleResponsiveModalDate}>
        <DateRangePicker
          onChange={(item: any) => {
            console.log("the date", item.selection, item);
            tabContents();
            setState([item.selection]);
          }}
          // onChange={item => setState([item.selection])}
          // showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={state}
          direction="horizontal"
        />
      </Modal>

      <h1>Slots</h1>

      <div>
        <CommonSearchBar
          name={name}
          searchTitle={"Search Slots..."}
          search={(e: any) => searchSlots(e)}
          openAddModal={addEvent}
          searchByName={searchByName}
          filterValueChanged={filterValueChangeByHotelsOrOutlets}
          filters={[
            {
              label: "Hotels",
              name: "hotels",
              placeholder: "Please select a hotel",
              options: hotelOptionsListForSearch(hotelsList),
              isSelect: true,
            },

            {
              label: "Outlets",
              name: "outlets",
              placeholder: "Please select a outlet",
              options: outletOptionsListForSearch(outletsList),
              isSelect: true,
            },
          ]}
        />
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            marginBottom: "10px",
          }}
        >
          {state && (
            <span
              style={{
                background: "lightgrey",
                width: "20%",
                display: "flex",
                justifyContent: "center",
                borderRadius: "10px",
                padding: "6px",
              }}
              onClick={() => setResponsiveModalDate(true)}
            >
              {moment(state[0]?.startDate).format("MMM DD")} -{" "}
              {moment(state[0]?.endDate).format("MMM DD")}
            </span>
          )}
        </div>
        <div>
          <Col xl={12}>
            <Card>
              <Card.Body>
                <Tab.Container defaultActiveKey="Profile">
                  <Nav
                    as="ul"
                    variant="pills"
                    justify
                    className="navtab-bg"
                    style={{
                      overflowX: "auto",
                      overflowY: "hidden",
                      flexWrap: "nowrap",
                    }}
                  >
                    {(tabContents() || []).map((tab, index) => {
                      return (
                        <Nav.Item as="li" key={index}>
                          <Nav.Link
                            className="cursor-pointer"
                            href="#"
                            style={{
                              cursor: "pointer",
                            }}
                            eventKey={tab.dateShow}
                            // eventKey={(moment(startDate).format("MMM DD") === moment(endDate).format("MMM DD")) ? moment(startDate).format("MMM DD") : ''}
                            onClick={() => {
                              if (
                                moment(new Date(), "DD.MM.YYYY").diff(
                                  moment(tab.dateShow, "DD.MM.YYYY")
                                ) < 0
                              ) {
                                setState((prevState: any[]) => [
                                  {
                                    ...prevState[0],
                                    endDate: moment(tab.date).toDate(),
                                    startDate: moment(tab.date).toDate(),
                                  },
                                ]);
                              }
                              if (
                                moment(new Date(), "DD.MM.YYYY").diff(
                                  moment(tab.dateShow, "DD.MM.YYYY")
                                ) > 0
                              ) {
                                setState((prevState: any[]) => [
                                  {
                                    ...prevState[0],
                                    startDate: moment(tab.date).toDate(),
                                    endDate: moment(tab.date).toDate(),
                                  },
                                ]);
                              }
                            }}
                          >
                            {tab.dateShow}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                  </Nav>
                </Tab.Container>
              </Card.Body>
            </Card>
          </Col>
        </div>
      </div>
      <Col>
        <Card>
          <Card.Body>
            {/* <div><img src="../../assets/images/new-release.png" alt="" width={100} height={100}/></div> */}
            {slotsloading ? (
              <Loader />
            ) : (
              <Table
                columns={columns}
                data={SlotsTableColumns(slotsList)}
                isSortable={true}
              />
            )}
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={slotsList?.totalCount}
              pageSize={limit}
              onPageChange={(page: any) => {
                setCurrentPage(page);
                navigate(
                  `/slots/list/${page}/${limit}/${name}/${hotel}/${outlet}?startDate=${moment(
                    state[0].startDate
                  )
                    .startOf("day")
                    .format("YYYY-MM-DDThh:mm:ss.000[Z]")}&endDate=${moment(
                    state[0].endDate
                  )
                    .endOf("day")
                    .format("YYYY-MM-DDThh:mm:ss.000[Z]")}`
                );
                dispatch(listSlotsLoading());
                dispatch(
                  listingSlots({
                    hotel: hotel !== "all" ? hotel : "",
                    outlet: outlet !== "all" ? outlet : "",
                    name: name !== "all" ? name : "",
                    page: page,
                    limit: 50,
                    startDate: moment(state[0].startDate)
                      .startOf("day")
                      .utc()
                      .format(),
                    endDate: moment(state[0].endDate)
                      .endOf("day")
                      .utc()
                      .format(),
                  })
                );
              }}
            />
            {
              <CreateGroup
                outletsList={outletsList}
                addParam={addParam}
                deleteParam={deleteParam}
                editParam={editParam}
                deletGroup={deleteEvent}
                responsiveModal={responsiveModal}
                toggleResponsiveModal={toggleResponsiveModal}
              />
            }
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default SlotListingPage;
