import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { Row, Col, Card, Dropdown, Modal, Button, Form } from "react-bootstrap";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { editSlots, listingSlotsById } from "../../redux/slots/actions";
import { RootState } from "../../redux/store";
import { listingUsers } from "../../redux/users/actions";

import Select from "react-select";

import avatar1 from "../../assets/images/users/5856.jpg";

import buisnessIcon from "../../assets/images/users/business.svg";
import moment from "moment";
import ContactDetails from "../apps/Contacts/List/ContactDetails";
import List from "../apps/Contacts/List";
import StatisticsWidget2 from "../../components/StatisticsChartWidget2";
import { Typeahead } from "react-bootstrap-typeahead";

import { listingGroups } from "../../redux/groups/actions";
import { useForm, Controller } from "react-hook-form";
const ConditionField = ({
  control,
  register,
  watch
}: any) => {
  
  return(watch(`dedicatedFilter`));

}
const EditSlotDetails = () => {
  const methods = useForm({
    defaultValues: {
      selectedPrivateGroup: [],
      selectedPublicGroup: [],
      selectedExclusiveUsers: [],
      allocatedUsers: [],
      dedicatedFilter: false,
    },
  });
  const {
    handleSubmit,
    register,
    control,
    getValues,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [vacancyvalue, setVacanyValue] = useState();
  const [relesevalue, setReleseValue] = useState();

  const { slotsList, positions, page, limit, selectedslotDetails } =
    useSelector((state: RootState) => state.SlotsReducer);
  const { usersList } = useSelector((state: RootState) => state.UsersReducer);
  const { groupsList, grouploading } = useSelector(
    (state: RootState) => state.GroupsReducer
  );
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    dispatch(listingSlotsById(id));
    dispatch(
      listingUsers({ userStatus: "VERIFIED", page: "1", limit: "10000" })
    );
    dispatch(listingGroups({ page: page, limit: limit }));
  }, [id]);

  useEffect(() => {
    const publicgrparray: any = [];
    const pvrtgrparr: any = [];
    const excgrparray: any = [];
    const allocatedarray:any = [];
    selectedslotDetails?.selectedPrivateGroup?.map((prvtgrp: any, index: any) =>
      pvrtgrparr.push({
        // id: index,
        value: prvtgrp._id,
        label: prvtgrp.groupTitle ? prvtgrp.groupTitle : " ",
      })
    );
    selectedslotDetails?.selectedPublicGroup?.map((pubgrp: any) =>
      publicgrparray.push({
        value: pubgrp._id,
        label: pubgrp.groupTitle ? pubgrp.groupTitle : " ",
      })
    );
    selectedslotDetails?.selectedExclusiveUsers?.map((grp: any) =>
      excgrparray.push({
        value: grp?._id,
        label: grp?.name ? grp?.name : " ",
      })
    );
    selectedslotDetails?.confirmedRequests?.map((grp: any) =>
      allocatedarray.push({
        value: grp?._id,
        label: grp?.name ? grp?.name : " ",
      })
    );
    console.log("the val", pvrtgrparr);
    // setValue( "selectedPrivateGroup",pvrtgrparr )
    reset({
      selectedPrivateGroup: pvrtgrparr,
      selectedPublicGroup: publicgrparray,
      selectedExclusiveUsers: excgrparray,
      allocatedUsers: allocatedarray,
      dedicatedFilter:
        selectedslotDetails?.selectedPublicGroup?.length > 0 ||
        selectedslotDetails?.selectedPrivateGroup?.length > 0 ||
        selectedslotDetails?.selectedExclusiveUsers?.length > 0 ||
        selectedslotDetails?.allocatedUsers?.length > 0 || selectedslotDetails?.confirmedRequests?.length > 0,
    });
  }, [selectedslotDetails]);

  useEffect(() => {
    setState({
      confirmedRequests: selectedslotDetails?.confirmedRequests
        ? selectedslotDetails?.confirmedRequests
        : [],
      waitingRequests: selectedslotDetails?.waitingRequests
        ? selectedslotDetails?.waitingRequests
        : [],
      allUsers: usersList,
    });
  }, [selectedslotDetails, usersList]);

  const [dedicatedFilter, setDedicatedFilter] = useState<any>();

  const [state, setState] = useState<any>({
    confirmedRequests: selectedslotDetails?.confirmedRequests,
    waitingRequests: selectedslotDetails?.waitingRequests,
    allUsers: usersList,
  });

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const move = (
    source: Iterable<unknown> | ArrayLike<unknown>,
    destination: Iterable<unknown> | ArrayLike<unknown>,
    droppableSource: { index: number; droppableId: string | number },
    droppableDestination: { index: number; droppableId: string | number }
  ) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result: any = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    console.log("the value", result);
    return result;
  };

  /**
   * Gets the list
   */
  const getList = (id: string) => {
    const modifiedState: any = { ...state };
    const stateTasks: any = modifiedState[id] && modifiedState[id];
    return stateTasks;
  };
  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );
      let localState: any = { ...state };
      localState[source.droppableId] = items;
      setState(localState);
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );
      const localState = { ...state, ...result };
      setState(localState);
    }

    console.log("te value ID", state);
  };
  const saveSlotDetails = () => {
    console.log("thhes tat", getValues());
    let allocatedUsersarr: any[] = [];
    let selectedExclusiveUsersarr: any[]=[];
    let selectedPrivateGrouparr: any[] = [];
    let selectedPublicGrouparr: any[] = [];

    let confirmedRequetArr : any[] =[];
    let waitingRequestArr : any[] = [];
    getValues()?.selectedExclusiveUsers.map((user: any) => selectedExclusiveUsersarr.push(user.value));
    // getValues()?.selectedExclusiveUsers = selectedExclusiveUsersarr;
    getValues()?.allocatedUsers.map((user: any) => allocatedUsersarr.push(user.value));
    // getValues()?.allocatedUsers = allocatedUsersarr;
    getValues()?.selectedPrivateGroup.map((privategr: any) =>
      selectedPrivateGrouparr.push(privategr.value)
    );
    // getValues()?.selectedPrivateGroup = selectedPrivateGrouparr;
    getValues()?.selectedPublicGroup.map((publicgr: any) =>
      selectedPublicGrouparr.push(publicgr.value)
    );
    // getValues()?.selectedPublicGroup = selectedPublicGrouparr;
    state?.confirmedRequests?.map((confirm:any) => confirmedRequetArr.push(confirm?._id))
    state?.waitingRequests?.map((waiting:any) => waitingRequestArr.push(waiting?._id))
    dispatch(
      editSlots({
        _id: id,
        vacancy: vacancyvalue ? vacancyvalue : selectedslotDetails?.vacancy,
        release: relesevalue ? relesevalue : selectedslotDetails?.release,
        confirmedRequests: confirmedRequetArr,
        waitingRequests: waitingRequestArr,
        selectedPrivateGroup: selectedPrivateGrouparr,
        selectedPublicGroup: selectedPublicGrouparr,
        selectedExclusiveUsers: selectedExclusiveUsersarr,
        allocatedUsers: allocatedUsersarr,
        priority: selectedslotDetails?.priority,
        dedicatedFilter: getValues()?.dedicatedFilter,
      })
    );
    navigate(-1)
  };

  const vacanySetValue = (e: any) => setVacanyValue(e);
  const releaseSetValue = (e: any) => setReleseValue(e);

  const UsersListForSearch = (data: any) => {
    let users: any = [];
    data?.data?.map((list: any, index: any) =>
      users.push({
        // id: index,
        value: list._id,
        label: list.name ? list.name : " ",
      })
    );
    return users;
  };

  const GroupsListForSearch = (data: any, type: any) => {
    let groups: any = [];
    data?.data?.map((list: any, index: any) => {
      if (list.groupType === type) {
        console.log("the ye0", type);
        return groups.push({
          // id: index,
          value: list._id,
          label: list.groupTitle ? list.groupTitle : " ",
        });
      }
    });

    return groups;
  };

  const dedicatedFilterValueSetting = () => {
    if (
      selectedslotDetails?.selectedPublicGroup?.length > 0 ||
      selectedslotDetails?.selectedExclusiveUsers?.length > 0 ||
      selectedslotDetails?.selectedPrivateGroup?.length > 0
    ) {
      return "true";
    } else {
      return "false";
    }
  };
  return (
    <>
      <Row
        style={{
          display: "flex",
          justifyContent: "flex-end",
          fontSize: "20px",
          paddingTop: "5px",
          cursor: "pointer",
        }}
        onClick={() => navigate(-1)}
      >
        <i className="bi bi-x"></i>
      </Row>
      <Row style={{ marginTop: "2%" }}>
        <Col md={6}>
          <ContactDetails
            contacts={[
              {
                id: 1,
                priority: selectedslotDetails?.priority,
                name: selectedslotDetails?.shiftName,
                avatar: buisnessIcon,
                position: selectedslotDetails?.hotelName,
                website: selectedslotDetails?.outletName,
                posts: `${moment(selectedslotDetails?.slotStart).format(
                  "YYYY/MM/DD"
                )}`,
                endDate: `${moment(selectedslotDetails?.endDate).format(
                  "YYYY/MM/DD"
                )}`,
                startTime: selectedslotDetails?.startTime,
                endTime: selectedslotDetails?.endTime,
                hourlyPay: selectedslotDetails?.hourlyPay,
                totalPay: selectedslotDetails?.totalPay,
                followers: selectedslotDetails?.position?.positionName,
                release: selectedslotDetails?.release,
                vacancy: selectedslotDetails?.vacancy,
              },
            ]}
            vacanySetValue={vacanySetValue}
            releaseSetValue={releaseSetValue}
          />
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <StatisticsWidget2
                variant="blue"
                description="Confirmed Requests"
                stats={selectedslotDetails?.confirmedRequests?.length}
                icon="fe-user"
                progress={(
                  (selectedslotDetails?.confirmedRequests?.length * 100) /
                  selectedslotDetails?.release
                ).toFixed(2)}
                counterOptions={{
                  prefix: "",
                }}
                target={selectedslotDetails?.release}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Card>
          <Card.Body>
            <div>
              {" "}
              <h4 className="mt-3">
                Slot Status: {selectedslotDetails?.status}
              </h4>
            </div>
            <div>
              {" "}
              <h4 className="mt-3">
                Priority: {selectedslotDetails?.priority}
              </h4>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "baseline",
              }}
            >
              {" "}
              <h4 className="mt-3">Dedicated Filter:</h4>
              &nbsp;
              <span>
                <Form.Group>
                  <Controller
                    name="dedicatedFilter"
                    control={control}
                    render={({ field }: any) => (
                      <Form.Check
                        type="checkbox"
                        id="dedicatedFilter"
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                </Form.Group>
              </span>
            </div>
            {ConditionField({control,register,watch}) && (
                 <>
                 <div>
                 <Controller
                  control={control}
                  name={`allocatedUsers`}
                  render={({ field, fieldState }: any) => (
                    <div className="mb-3">
                      <label htmlFor="typeahead" className="form-label">
                        Dedicated Users
                      </label>
                      <Select
                           {...field}
                           isMulti
                           options={UsersListForSearch(usersList)}
                           value={field.value}
                         />
                      {/* <Typeahead
                        {...field}
                        id="basic-typeahead-single"
                        multiple={true}
                        // onInputChange={(e) => searchByName('name', e)}
                        options={UsersListForSearch(usersList)}
                        placeholder="Dedicated Users" /> */}
                    </div>
                  )} />
                 </div>
                 <div>
                   <Controller
                     control={control}
                     name={`selectedPrivateGroup`}
                     render={({ field, fieldState }: any) => (
                       <div className="mb-3">
                         <label htmlFor="typeahead" className="form-label">
                           Private Group
                         </label>
                         <Select
                           {...field}
                           isMulti
                           options={GroupsListForSearch(groupsList, "Private")}
                           value={field.value}
                         />
                         {/* <Typeahead
                           {...field}
                           id="basic-typeahead-single"
                           multiple={true}
                           register={register}
                           onChange={(e) => console.log("the cahnge", e)}
                           selected={getValues().selectedPrivateGroup}
                           // disabled
                           options={GroupsListForSearch(groupsList, "Private")}
                           placeholder="Private Group"
                         /> */}
                       </div>
                     )}
                   />
                 </div>
                 <div>
                   <Controller
                     control={control}
                     name={`selectedPublicGroup`}
                     render={({ field, fieldState }: any) => (
                       <div className="mb-3">
                         <label htmlFor="typeahead" className="form-label">
                           Public Group
                         </label>
                         <Select
                           {...field}
                           isMulti
                           options={GroupsListForSearch(groupsList, "Public")}
                           value={field.value}
                         />
                         {/* <Typeahead
                           {...field}
                           id="basic-typeahead-single"
                           multiple={true}
                           register={register}
                           // disabled
                           // onInputChange={(e) => searchByName('name', e)}
                           selected={getValues().selectedPublicGroup}
                           options={GroupsListForSearch(groupsList, "Public")}
                           placeholder="Public Groups"
                         /> */}
                       </div>
                     )}
                   />
                 </div>
                 <div>
                   <Controller
                     control={control}
                     name={`selectedExclusiveUsers`}
                     render={({ field, fieldState }: any) => (
                       <div className="mb-3">
                         <label htmlFor="typeahead" className="form-label">
                           Exclusive Users
                         </label>
                         <Select
                           {...field}
                           isMulti
                           options={UsersListForSearch(usersList)}
                           value={field.value}
                         />
                       </div>
                     )}
                   />
                 </div>
                
               </>
            )
           
            }
          </Card.Body>
        </Card>
      </Row>
      <Row>
        <DragDropContext onDragEnd={onDragEnd}>
          {/* todo */}
          <Droppable droppableId="confirmedRequests">
            {(provided, snapshot) => (
              <Col lg={6} ref={provided.innerRef}>
                <Card style={{ minHeight: "60vh", overflow: "scroll" }}>
                  <Card.Body>
                    <h5 className="header-title">Confirmed Requests</h5>
                    <p className="sub-header">
                      Your awesome text goes here. Your awesome text goes here.
                    </p>
                    {state.confirmedRequests?.length === 0 && (
                      <p className="text-center text-muted pt-3 mb-0">
                        No Users
                      </p>
                    )}

                    <ul
                      className="sortable-list tasklist list-unstyled"
                      id="confirmedRequests"
                    >
                      {(state?.confirmedRequests || []).map(
                        (item: any, index: any) => (
                          <Draggable
                            key={item._id}
                            draggableId={item._id + ""}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <li
                                style={{ borderRadius: "10px" }}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={avatar1}
                                    className="rounded-circle"
                                    alt=""
                                    width={"100px"}
                                    height={"100px"}
                                  />
                                  <h5>{item.name}</h5>
                                </div>
                              </li>
                            )}
                          </Draggable>
                        )
                      )}
                      {provided.placeholder}
                    </ul>
                  </Card.Body>
                </Card>
                {provided.placeholder}
              </Col>
            )}
          </Droppable>

          {/* in progress */}
          <Droppable droppableId="waitingRequests">
            {(provided, snapshot) => (
              <Col lg={6} ref={provided.innerRef}>
                <Card style={{ minHeight: "60vh", overflow: "scroll" }}>
                  <Card.Body>
                    <h5 className="header-title">Waiting List</h5>
                    <p className="sub-header">Your awesome text goes here.</p>

                    {state.waitingRequests?.length === 0 && (
                      <p className="text-center text-muted pt-3 mb-0">
                        No Users
                      </p>
                    )}

                    <ul
                      className="sortable-list tasklist list-unstyled"
                      id="inprogress"
                    >
                      {(state.waitingRequests || []).map(
                        (item: any, index: any) => (
                          <Draggable
                            key={item?._id}
                            draggableId={item?._id + ""}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <li
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={avatar1}
                                    className="rounded-circle"
                                    alt=""
                                    width={"100px"}
                                    height={"100px"}
                                  />
                                  <h5>{item.name}</h5>
                                </div>
                              </li>
                            )}
                          </Draggable>
                        )
                      )}
                      {provided.placeholder}
                    </ul>
                  </Card.Body>
                </Card>
                {provided.placeholder}
              </Col>
            )}
          </Droppable>
        </DragDropContext>
      </Row>

      <Button
        style={{ float: "right" }}
        variant="success"
        className="waves-effect waves-light"
        onClick={(e) => saveSlotDetails()}
      >
        <i className="mdi mdi-plus-circle me-1"></i> Save Slot Details
      </Button>
    </>
  );
};

export default EditSlotDetails;
