import { Form } from "react-bootstrap";

// table columns
export const WalletPadiListTableColumns =  (data: any) => {
   
const arr:any= [];
 data?.data?.map((details:any) => {
     arr.push({
        id: details._id,
        userName: 
        <a href={`/users/list/edit/${details?.userId}`}> {details.userName}</a>,
        amount:details.amount,
        bankName: details.bankName,
        bankAccNo: details.bankAccNo,
        contactNumber: details.contactNumber,
        walletBalance: details.walletBalance,
        isApproved: details.isApproved ? "Approved" : "Not Approved"
       
    }) 
})

return arr;
}

export const WalletRedeemListTableColumns =  (data: any, approveRedeem: any) => {
  console.log('the wallet tal', data)
const arr:any= [];
 data?.data?.map((details:any) => {
     arr.push({
        id: details._id,
        userName: 
        <a href={`/users/list/edit/${details?.userName}`}> {details.userName}</a>,
        amount:details.amount,
        bankName: details.bankName,
        bankAccNo: details.bankAccNo,
        contactNumber: details.contactNumber,
        walletBalance: details.walletBalance,
        isApproved:  <Form>
        <Form.Check
          defaultChecked={details.isApproved ? true: false}
          type="switch"
          id="custom-switch"
          onChange={(e) => approveRedeem(e.target.checked, details)}
        />
      </Form>
    }) 
})

return arr;
}