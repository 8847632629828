import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useSearchParams,
  useParams,
} from "react-router-dom";
import { Col, Card, Nav, Tab, Modal } from "react-bootstrap";
import Table from "../../components/Table";
import "moment-timezone";
import moment from "moment";
import { addDays } from "date-fns";
//dummy data
import classNames from "classnames";
import { SupportTableColumns } from "./data";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
// store
import {
  listingSupports,
  editSupports,
  addingSupports,
  updateAdminStatus,
  editSelectedSupports,
  deleteSupports,
} from "../../redux/support/actions";
import { RootState } from "../../redux/store";
import CommonSearchBar from "../../layouts/SearchBar";
// import Notifications from '../uikit/Notifications';
// import ToastResponse from '../../components/Toaster';
import Loader from "../../components/Loader";
import {
  listingStaffStatusList,
  listingStaffStatusListById,
  listingStaffStatusListLoading,
} from "../../redux/staffStatus/actions";
import { listingOutlets } from "../../redux/outlets/actions";
import { OutletsActionTypes } from "../../redux/outlets/constants";
import { listingHotels } from "../../redux/hotels/actions";
import { DateRangePicker } from "react-date-range";
import Pagination from "../../components/customPaginationComponent/customPagination";
// import DefaultToasts from '../uikit/Notifications';

const StaffStatusList = () => {
  const navigate = useNavigate();
  let { search } = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listingHotels({ page: 0, limit: 10 }));
  }, []);
  const [responsiveModalDate, setResponsiveModalDate] = useState(false);
  let currentDate = new Date();

  const [state, setState] = useState<any>([
    {
      startDate: new Date(currentDate).setMonth(currentDate.getMonth() - 6),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const toggleResponsiveModalDate = () =>
    setResponsiveModalDate(!responsiveModalDate);
  const tabContents = () => {
    let i;
    let j;
    const arr = [];
    for (i = 7; i >= 0; i--) {
      arr.push({
        index: i,
        date: moment(new Date()).subtract(i, "days"),
        dateShow: moment(new Date()).subtract(i, "days").format("MMM DD"),
      });
    }
    for (j = 1; j <= 7; j++) {
      arr.push({
        index: j,
        date: moment(new Date()).add(j, "days"),
        dateShow: moment(new Date()).add(j, "days").format("MMM DD"),
      });
    }
    return arr;
  };
  // const {name, isAvailable} = useParams();
  const { staffStatusList, staffStatusLoading, page, limit } = useSelector(
    (state: RootState) => state.StaffStatusReducer
  );
  const { hotelsList } = useSelector((state: RootState) => state.HotelsReducer);
  const { outletsList } = useSelector(
    (state: RootState) => state.OutletsReducer
  );

  console.log("the statuff status", staffStatusList);
  const query = new URLSearchParams(search);

  const startDate = query.get("startDate");
  const endDate = query.get("endDate");

  useEffect(() => {
    navigate(
      `/staff-status/list/${page}/${limit}/all/all?startDate=${moment(
        state[0].startDate
      )
        .startOf("day")
        .utc().format()}&endDate=${moment(
        state[0].endDate
      )
        .endOf("day")
        .utc().format()}`
    );
    dispatch(listingStaffStatusListLoading());
    let utcTime = moment((state[0].startDate)).utc();  // Let's assume current UTC time is 2024-12-22 15:00 UTC
let startOfDayUtc = utcTime.startOf('day');  // Start of the day in UTC (2024-12-22 00:00 UTC)

let localStartOfDay = startOfDayUtc.tz('Asia/Kolkata');  // Convert to New York time

console.log("Start of the day in UTC:", moment((state[0].startDate)).startOf('day').utc().format());
// console.log("Start of the day in New York:", localStartOfDay.format());
    // console.log("the to date", moment((state[0].startDate)).utc().startOf('day').format("YYYY-MM-DDThh:mm:ss.000[Z]"))
    dispatch(
      listingStaffStatusList({
        page: currentPage,
        limit: 50,
        startDate: moment((state[0].startDate)).startOf('day').utc().format(),
        endDate: moment((state[0].endDate)).endOf('day').utc().format(),
      })
    );
  }, [state]);

  const columns = [
    {
      Header: "Date",
      accessor: "date",
      sort: false,
    },
    {
      Header: "Name",
      accessor: "fullName",
      sort: false,
    },
    {
      Header: "Contact Details",
      accessor: "contactNumber",
      sort: false,
    },
    {
      Header: "Hotel",
      accessor: "hotel",
      sort: false,
    },

    {
      Header: "Outlet",
      accessor: "outlet",
      sort: false,
    },
    {
      Header: "Shift Name",
      accessor: "shiftName",
      sort: false,
    },
    {
      Header: "Notification Status",
      accessor: "status",
      sort: false,
    },
    {
      Header: " Slot Link",
      accessor: "slotId",
      Cell: ({ cell }: any) => (
        <>
          <a href={`/slots/list/edit/${cell.row.values?.slotId}`}>Slot Link</a>
        </>
      ),
    },
    {
      Header: " ",
      accessor: "chat",
      Cell: ({ cell }: any) => (
        <>
          <span
            style={{ fontSize: "x-large", color: "green", cursor: "pointer" }}
          >
            <i
              onClick={() =>
                window.open(
                  `https://wa.me/${cell.row.original.contactNumber}`,
                  "_blank"
                )
              }
              className={classNames("fab", "fa-whatsapp", "mdi-account")}
            ></i>
          </span>
        </>
      ),
    },
  ];

  const sizePerPageList = [
    {
      text: "5",
      value: 5,
    },
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },
    {
      text: "All",
      value: StaffStatusList?.length,
    },
  ];

  const filterValueChangeByHotelsOrOutlets = (fieldname: any, e: any) => {
    console.log("the name , e", fieldname, e);
    if (fieldname === "hotels") {
      dispatch(
        listingOutlets(OutletsActionTypes.LIST_OUTLETS, {
          hotel: e[0]?.value,
          page: page,
          limit: limit,
        })
      );
      // dispatch(listingSlots({hotel: e[0]?.value, page:page, limit: limit, name: name, startDate: state[0].startDate, endDate: state[0].endDate}))
    } else if (fieldname === "outlets") {
      dispatch(listingStaffStatusListLoading());
      // dispatch(listingStaffStatusList({page:currentPage,limit: 50, startDate: moment(state[0].startDate).format("YYYY-MM-DDThh:mm:ss"), endDate: moment(state[0].endDate).format("YYYY-MM-DDThh:mm:ss")}))
      dispatch(
        listingStaffStatusListById({
          id: e[0]?.value,
          page: page,
          limit: limit,
          startDate: moment((state[0].startDate)).startOf('day').utc().format(),
          endDate: moment((state[0].endDate)).endOf('day').utc().format(),
        })
      );
    }
  };

  const hotelOptionsListForSearch = (data: any) => {
    let hotel: any = [];
    data?.data?.map((list: any, index: any) =>
      hotel.push({
        id: index,
        value: list._id,
        label: list.hotelName ? list.hotelName : " ",
      })
    );
    return hotel;
  };
  const outletOptionsListForSearch = (data: any) => {
    let hotel: any = [];
    data?.data?.map((list: any, index: any) =>
      hotel.push({
        id: index,
        value: list._id,
        label: list.outletName ? list.outletName : " ",
      })
    );
    return hotel;
  };
  const searchSupport = (e: any) => {
    // if(!e.target.value){
    //   navigate(`/support/all/all`);
    // }
    // else{
    //   navigate(`/support/${e.target.value}/all`);
    // }
    dispatch(listingStaffStatusListLoading());
    dispatch(
      listingStaffStatusList({
        page: currentPage,
        limit: 50,
        startDate: moment((state[0].startDate)).startOf('day').utc().format(),
        endDate: moment((state[0].endDate)).endOf('day').utc().format(),
        name: e.target.value,
      })
    );
  };
  const changeAvailability = (e: string, id: string) => {
    console.log("the vale", e, id);
    dispatch(updateAdminStatus({ _id: id, status: e }));
  };

  const searchByName = (name: any, value: any) => {
    if (name === "hotels") {
      dispatch(listingHotels({ name: value }));
    } else {
      dispatch(listingOutlets(OutletsActionTypes.LIST_OUTLETS, { name: name }));
    }
  };
  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <>
      <Modal show={responsiveModalDate} onHide={toggleResponsiveModalDate}>
        <DateRangePicker
          onChange={(item: any) => {
            console.log("the date", item.selection, item);
            setState([item.selection]);
          }}
          // onChange={item => setState([item.selection])}
          // showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={state}
          direction="horizontal"
        />
      </Modal>
      <h1>Staff Status</h1>
      {/* {supports.StaffStatusList.length > 0 && (
          <ToastResponse status={true} msg='Listed the Admins'/>
        )}

        {(Object.keys(supports?.listingerror ).length  !== 0 )&& (
          <ToastResponse status={false} msg="Something went wrong"/>
        )} */}
      <div>
        <CommonSearchBar
          name={"all"}
          searchTitle={"Search..."}
          search={(e: any) => searchSupport(e)}
          searchByName={searchByName}
          filterValueChanged={filterValueChangeByHotelsOrOutlets}
          filters={[
            {
              label: "Hotels",
              name: "hotels",
              placeholder: "Please select a hotel",
              options: hotelOptionsListForSearch(hotelsList),
              isSelect: true,
            },

            {
              label: "Outlets",
              name: "outlets",
              placeholder: "Please select a outlet",
              options: outletOptionsListForSearch(outletsList),
              isSelect: true,
            },
          ]}
        />
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            marginBottom: "10px",
          }}
        >
          {state && (
            <span
              style={{
                background: "lightgrey",
                width: "20%",
                display: "flex",
                justifyContent: "center",
                borderRadius: "10px",
                padding: "6px",
              }}
              onClick={() => setResponsiveModalDate(true)}
            >
              {moment(state[0]?.startDate).format("MMM DD")} -{" "}
              {moment(state[0]?.endDate).format("MMM DD")}
            </span>
          )}
        </div>
        <div>
          <Col xl={12}>
            <Card>
              <Card.Body>
                <Tab.Container defaultActiveKey="Profile">
                  <Nav
                    as="ul"
                    variant="pills"
                    justify
                    className="navtab-bg"
                    style={{
                      overflowX: "auto",
                      overflowY: "hidden",
                      flexWrap: "nowrap",
                    }}
                  >
                    {(tabContents() || []).map((tab, index) => {
                      return (
                        <Nav.Item as="li" key={index}>
                          <Nav.Link
                            className="cursor-pointer"
                            href="#"
                            style={{
                              cursor: "pointer",
                            }}
                            eventKey={tab.dateShow}
                            // eventKey={(moment(startDate).format("MMM DD") === moment(endDate).format("MMM DD")) ? moment(startDate).format("MMM DD") : ''}
                            onClick={() => {
                              console.log(
                                "diff",
                                moment(new Date(), "DD.MM.YYYY").diff(
                                  moment(tab.dateShow, "YYYY-MM-DD", "days")
                                )
                              );
                              if (
                                moment(new Date(), "DD.MM.YYYY").diff(
                                  moment(tab.dateShow, "DD.MM.YYYY")
                                ) < 0
                              ) {
                                setState((prevState: any[]) => [
                                  {
                                    ...prevState[0],
                                    endDate: moment(tab.date).toDate(),
                                    startDate: moment(tab.date).toDate(),
                                  },
                                ]);
                              }
                              if (
                                moment(new Date(), "DD.MM.YYYY").diff(
                                  moment(tab.dateShow, "DD.MM.YYYY")
                                ) > 0
                              ) {
                                setState((prevState: any[]) => [
                                  {
                                    ...prevState[0],
                                    startDate: moment(tab.date).toDate(),
                                    endDate: moment(tab.date).toDate(),
                                  },
                                ]);
                              }
                            }}
                          >
                            {tab.dateShow}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                  </Nav>
                </Tab.Container>
              </Card.Body>
            </Card>
          </Col>
        </div>
      </div>
      <Col>
        <Card>
          <Card.Body>
            {staffStatusLoading ? (
              <Loader />
            ) : (
              <Table
                columns={columns}
                data={SupportTableColumns(staffStatusList, changeAvailability)}
                pageSize={50}
                sizePerPageList={sizePerPageList}
                isSortable={true}
                pagination={false}
              />
            )}
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={
                SupportTableColumns(staffStatusList, changeAvailability).length
              }
              pageSize={50}
              onPageChange={(page: any) => 
               { 
                setCurrentPage(page)
                navigate(
                  `/staff-status/list/${page}/${limit}/all/all?startDate=${moment(
                    state[0].startDate
                  )
                    .startOf("day")
                    .utc().format()}&endDate=${moment(
                    state[0].endDate
                  )
                    .endOf("day")
                    .utc().format()}`
                );
                dispatch(listingStaffStatusListLoading());
                dispatch(
                  listingStaffStatusList({
                    page: currentPage,
                    limit: 50,
                    startDate: moment((state[0].startDate)).startOf('day').utc().format(),
                    endDate: moment((state[0].endDate)).endOf('day').utc().format(),
                  })
                );
               }
              }
            />
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default StaffStatusList;
