import { APICore } from "./apiCore";


const api = new APICore();

// account
function listWalletConfirmation(payload: any) {
    const baseUrl = "wallet/confirmationList"; 
  const params =  Object.keys(payload)
            .map((key) => key + "=" +  payload[key])
            .join("&")
  return api.get(`${baseUrl}?${params}`)
}

function redeemList(payload: any) {
    const baseUrl = "wallet/redeemList"; 
  const params =  Object.keys(payload)
            .map((key) => key + "=" +  payload[key])
            .join("&")
  return api.get(`${baseUrl}?${params}`)
}

function paidList(payload: any) {
    const baseUrl = "wallet/paidList"; 
  const params =  Object.keys(payload)
            .map((key) => key + "=" +  payload[key])
            .join("&")
  return api.get(`${baseUrl}?${params}`)
}

function createWalletRequest(payload: any) {
  const baseUrl = "/wallet/create";
  return api.create(`${baseUrl}`, payload);
}

function approveWalletRequest(payload: any) {
    const baseUrl = `wallet/approve/${payload}`;
    return api.updatePatch(`${baseUrl}`, {});
  }

  function approveBankRequest(payload: any) {
    const baseUrl = `wallet/bankTransfer/${payload}`;
    return api.updatePatch(`${baseUrl}`, {});
  }


  function approveRedeemRequest(payload: any) {
    
    const baseUrl = `wallet/acceptWalletRedeem/${payload}`;
    return api.get(`${baseUrl}`);
  }


export { listWalletConfirmation,approveBankRequest,redeemList, paidList, createWalletRequest, approveWalletRequest,approveRedeemRequest};
