import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  listWalletConfirmation  as listWalletConfirmationApi,
  paidList as paidListApi,
  redeemList as redeemListApi,
  createWalletRequest as createWalletRequestApi,
  approveRedeemRequest as approveRedeemRequestApi,
  approveWalletRequest as approveWalletRequestApi
} from "../../helpers/api/wallet";


// constants
import { WalletActionTypes } from "./constants";
// actions
import { apiUsersResponseError, apiUsersResponseSuccess } from "./actions";



function* listConfirmationWallets({payload: searchParams,
  type}: any): SagaIterator{
  try{
    const response = yield call(listWalletConfirmationApi, searchParams);
    yield put(apiUsersResponseSuccess(WalletActionTypes.LIST_WALLET_CONFIRMATION_LIST_LOADING, {}))
    yield put(apiUsersResponseSuccess(WalletActionTypes.LIST_WALLET_CONFIRMATION_LIST, response.data.result))
  }
  catch(err: any){
    yield put(apiUsersResponseError(WalletActionTypes.LIST_WALLET_CONFIRMATION_LIST, err))
  }
}


function* listConfirmationWalletsLoading(): SagaIterator{
  try{
    yield put(apiUsersResponseSuccess(WalletActionTypes.LIST_WALLET_CONFIRMATION_LIST_LOADING, {}))
    // yield put(apiUsersResponseSuccess(WalletActionTypes.LIST_WALLET_CONFIRMATION_LIST, response.data.result))
  }
  catch(err: any){
    yield put(apiUsersResponseError(WalletActionTypes.LIST_WALLET_CONFIRMATION_LIST_LOADING, err))
  }
}

function* listRedeemList({payload: searchParams,
  type}: any): SagaIterator{
  try{
    const response = yield call(redeemListApi, searchParams);
    yield put(apiUsersResponseSuccess(WalletActionTypes.LIST_REDEEM_LIST_LOADING, {}))
    yield put(apiUsersResponseSuccess(WalletActionTypes.LIST_REDEEM_LIST, response.data.result))
  }
  catch(err: any){
    yield put(apiUsersResponseError(WalletActionTypes.LIST_REDEEM_LIST, err))
  }
}

function* paidList({payload: searchParams,
  type}: any): SagaIterator{
  try{
    const response = yield call(paidListApi, searchParams);
 
    yield put(apiUsersResponseSuccess(WalletActionTypes.LIST_PAID_LIST_LOADING, {}))
    yield put(apiUsersResponseSuccess(WalletActionTypes.LIST_PAID_LIST, response.data.result))
  }
  catch(err: any){
    yield put(apiUsersResponseError(WalletActionTypes.LIST_PAID_LIST, err))
  }
}

function* createManualWalletRequest({payload: searchParams,
  type}: any): SagaIterator{
  try{
    const response = yield call(createWalletRequestApi, searchParams);
    yield put(apiUsersResponseSuccess(WalletActionTypes.CREATE_MANUAL_WALLET_REQUEST_LOADING, {}))
    yield put(apiUsersResponseSuccess(WalletActionTypes.CREATE_MANUAL_WALLET_REQUEST, response.data.result))
    
  }
  catch(err: any){
    yield put(apiUsersResponseError(WalletActionTypes.CREATE_MANUAL_WALLET_REQUEST, err))
  }
}

function* acceptRedeemList({payload: searchParams,
  type}: any): SagaIterator{
  try{
    console.log('the response in  users', searchParams)
    const response = yield call(approveRedeemRequestApi, searchParams.id);
    yield call(redeemListApi, searchParams.details)
    yield put(apiUsersResponseSuccess(WalletActionTypes.ACCEPT_PAID_LIST, response.data.result))
  }
  catch(err: any){
    yield put(apiUsersResponseError(WalletActionTypes.ACCEPT_PAID_LIST, err))
  }
}

function* acceptWallet({payload: searchParams,
  type}: any): SagaIterator{
  try{
    console.log('the response in  users', searchParams)
    const response = yield call(approveWalletRequestApi, searchParams.id);
    yield call(listConfirmationWallets, searchParams.details)
    yield put(apiUsersResponseSuccess(WalletActionTypes.WALLET_APPROVAL, response.data.result))
  }
  catch(err: any){
    yield put(apiUsersResponseError(WalletActionTypes.WALLET_APPROVAL, err))
  }
}



function* resetValues({
  type,
  payload,
}: any): SagaIterator{
  try{
    
    yield put(apiUsersResponseSuccess(WalletActionTypes.RESET_WALLET,{}))
  }
  catch(err:any){
    yield put(apiUsersResponseError(WalletActionTypes.RESET_WALLET, err));
 
  }
}

export function* watchListingConfirmationWallets() {
  
  yield takeEvery(WalletActionTypes.LIST_WALLET_CONFIRMATION_LIST, listConfirmationWallets);
}


export function* watchListingConfirmationWalletsLoading() {
  
  yield takeEvery(WalletActionTypes.LIST_WALLET_CONFIRMATION_LIST_LOADING, listConfirmationWalletsLoading);
}
export function* watchRedeemList() {
  yield takeEvery(WalletActionTypes.LIST_REDEEM_LIST, listRedeemList);
}

export function* watchPaidList() {
  yield takeEvery(WalletActionTypes.LIST_PAID_LIST, paidList);
}

export function* watchCreateManual() {
  yield takeEvery(WalletActionTypes.CREATE_MANUAL_WALLET_REQUEST, createManualWalletRequest);
}

export function* watchAcceptPaidList() {
  yield takeEvery(WalletActionTypes.ACCEPT_PAID_LIST, acceptRedeemList);
}

export function* watchWalletApproval() {
  yield takeEvery(WalletActionTypes.WALLET_APPROVAL, acceptWallet);
}


function* WalletSaga() {
  yield all([
    fork(watchListingConfirmationWallets),
    fork(watchRedeemList),
    fork(watchPaidList),
    fork(watchCreateManual),
    fork(watchAcceptPaidList),
    fork(watchWalletApproval),
    fork(watchListingConfirmationWalletsLoading)
  ]);
}

export default WalletSaga;
