import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Card, Modal, Button, Tab, Nav } from "react-bootstrap";
import axios from "axios";
import Table from "../../components/Table";

//dummy data
import { records as data, expandableRecords } from "../tables/data";

import { useDispatch, useSelector } from "react-redux";
import { deleteUser, listingUsers } from "../../redux/users/actions";
import { RootState } from "../../redux/store";
import { OutletTableColumns, OutletUserTableColumns, UsersTableColumns } from "./data";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CommonSearchBar from "../../layouts/SearchBar";
import { createHotels, deleteHotel, editSelectedHotels, listingHotels, updateSelectedHotels } from "../../redux/hotels/actions";
import { useForm } from "react-hook-form";
import FormInput from "../../components/FormInput";
import { deleteOutlets, listingOutlets, listingOutletsByhotels, listingOutletsLoading } from "../../redux/outlets/actions";
import { listingOutletsUsers, listingUsersByOutel } from "../../redux/outletUsers/actions";
import { OutletsActionTypes } from "../../redux/outlets/constants";
import Pagination from "../../components/customPaginationComponent/customPagination";
import FileUpload from "../forms/FileUpload";
import Loader from "../../components/Loader";




const HotelOutLetList = () => {
  const {name , tab, page, limit} = useParams();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const deleteParam = query.get('delete');
  const editParam = query.get('edit');
  const addParam = query.get('add');
  
  const [responsiveModal, setResponsiveModal] = useState<boolean>((deleteParam) ? true : false);
  const [hotelImages, setHotelImages] = useState<any[]>()
  const [responsiveModalForUser, setResponsiveModalForUser] = useState(false);
  const {hotelsList, selectedHotels,hotelsloading}  = useSelector((state:RootState) => state.HotelsReducer)
  const {outletsUsersList} = useSelector((state:RootState) => state.OutletUsersReducer)
 const {outletsList, selectedOutlets}  =  useSelector((state:RootState) => state.OutletsReducer)
 
  const methods = useForm({
    defaultValues: {
      hotelName: selectedHotels?.hotelName,
      SOAmail: selectedHotels?.hotelName,
      abbreviation: selectedHotels?.hotelName,
      adminNumber:selectedHotels?.hotelName,
      // longitude: selectedHotels?.hotelName,
      // latitude: selectedHotels?.hotelName,
      zipCode: selectedHotels?.zipCode,
      outletImages: ""
    },
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    reset,
    formState,
    formState: { isSubmitSuccessful },
    
  } = methods;

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      sort:false,
    },
    {
      Header: "SOA Mail",
      accessor: "SOAmail",
      sort: false,
    },
    {
      Header: "Action",
      accessor: "action",
      sort: false,
      Cell: ({ cell}: any) => (
        <>

       <span  style={{cursor:"pointer"}} onClick={() => handleEditGroup(cell.row.original)}>
              <i className="bi bi-pencil-square"></i>
          </span> &nbsp;
          {hotelsloading? <Loader/>:(
            <>
            <span  style={{cursor:"pointer"}} onClick={() => handleDeleteGroup(cell.row.original)}>
          <i className="bi bi-trash3"></i>
              </span> </>
          )}
          
        </>
      )
    }
  ];
  const OutletColumns = [
    {
      Header: "Outlet Name",
      accessor: "outletName",
      sort:false,
    },
    {
      Header: "Hotel Name",
      accessor: "hotelName",
      sort: false,
    },
    {
      Header: "Action",
      accessor: "action",
      sort: false,
      Cell: ({ cell}: any) => (
        <>
       <span  onClick={() => handleEditOutlet(cell.row.original)}>
              <i className="bi bi-pencil-square"></i>
          </span> &nbsp;<span style={{cursor:"pointer"}} onClick={() => handleDeleteGroup(cell.row.original)}>
          <i className="bi bi-trash3"></i>
              </span> 
            &nbsp;
          <span style={{cursor:"pointer"}}>
          <i className="bi bi-eye" onClick={() => handleListUsersByOutlet(cell.row.original)}></i>
          </span>
        </>
      )
    }
  ];
  useEffect(() => {
    reset({
      
        hotelName: selectedHotels?.hotelName,
        SOAmail: selectedHotels?.SOAmail,
        abbreviation: selectedHotels?.abbreviation,
        adminNumber:selectedHotels?.adminNumber,
        // longitude: selectedHotels?.longitude,
        // latitude: selectedHotels?.latitude,
        zipCode: selectedHotels?.zipCode
      
    })
    // selectedHotels?.hotelLogo?.map((logo:any) => {
    //   axios
    //   .get(
    //     `http://localhost:5001/api/user/images/${logo}`)
    //     // `https://jobs.doingbydo.com/api/user/images/${logo}`)
    //   .then((response) => new Blob([response.data]))
    //   .then((blob) => {
    //     // const f = new File([blob],   'Grooming Image');
    //     const f = new File([blob], 'hotelLogo');
    //     const test = Object.assign(f, { preview: `http://localhost:5001/api/user/images/${logo}` })
       
    //     setHotelImages((prevData) => [...prevData, test]); 
    //     // formData.append("groomingImages", test);
    //   })
    // })
   console.log("the selecedeHotels", selectedHotels)
    OutletImageReRendering(selectedHotels?.hotelLogo,'hotelLogo',"","")
  },[selectedHotels])

  const OutletImageReRendering = async (
    data: any,
    name: any,
    url: any,
    type: any
  ) => {
    const URL_image = "https://jobs.doingbydo.com/api/user/images/";
    // const URL_image = "http://localhost:5001/api/user/images/";
    const res = await axios.get(`${URL_image}${data}`)
    .then(async (response) => await new Blob([response.data]))
    .then(async (blob) => {
      const f = await new File([blob], name);
      const test = await Object.assign(f, {
        preview: `${URL_image}${data}`,
      });
      setHotelImages((prevData) => [ test]); 
    });
    // const res = await data?.map((image: any, index: any) => {
   
    //   axios

    //     // https://jobs.doingbydo.com/api/user/images/
    //     .get(`${URL_image}${image}`)
    //     .then(async (response) => await new Blob([response.data]))
    //     .then(async (blob) => {
    //       const f = await new File([blob], name);
    //       const test = await Object.assign(f, {
    //         preview: `${URL_image}${image}`,
    //       });
    //       setHotelImages((prevData) => [...prevData, test]); 
    //     });
    // });

    return res;
  };
  

  const handleListUsersByOutlet  = (cell:any) =>{
  setResponsiveModalForUser(!responsiveModalForUser)
  dispatch(listingUsersByOutel({page:currentPage, limit: limit , outlet:cell.id}))
  }

  const handleEditGroup = (cell: any) => {
    console.log("the cell in edit", cell)
    localStorage.setItem("typeOfActionHotel", "edit")
    dispatch(editSelectedHotels(cell.id))
    // navigate(`?edit=${cell.id}`);
    setResponsiveModal(!responsiveModal)
  }

  const handleEditOutlet = (cell: any) => {
    dispatch(editSelectedHotels(cell.id))
    navigate(`/hotel-outlet/list/edit/${cell.id}`);
    setResponsiveModal(!responsiveModal)
  }

  const handleDeleteGroup = (cell: any) => {
     console.log('tp delete', cell)
     navigate(`?delete=${cell.id}`);
    setResponsiveModal(!responsiveModal);
  }

  const formData = new FormData();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  useEffect(() => {
    if(editParam || addParam || deleteParam) {
      setResponsiveModal(true)
    }
    if(editParam){
      dispatch(editSelectedHotels(editParam))
    }
    dispatch(listingHotels({page: currentPage,limit: 50}))
    // debugger;
    dispatch(listingOutlets(OutletsActionTypes.LIST_OUTLETS,{page: currentPage,limit: 50}))
  },[editParam , addParam])
  

  const searchHotels =(e:any) => {
    if(e.target.value === ""){
      navigate(`/hotel-outlet/list/${page}/${limit}/all/0`);
    }
    else{
      navigate(`/hotel-outlet/list/${page}/${limit}/${e.target.value}/0`);
    }
    dispatch(listingHotels({hotelName: e.target.value, page: currentPage,limit: 50}))
  }
  const searchOutlets =(e:any) => {
    if(e.target.value === ""){
      navigate(`/hotel-outlet/list/${page}/${limit}/all/1`);
    }
    else{
      navigate(`/hotel-outlet/list/${page}/${limit}/${e.target.value}/1`);
    }
    dispatch(listingOutletsByhotels({name: e.target.value, page: currentPage,limit: 50}))
    OutletCard(outletsList)
  }

  const filterValueChangeByUsersStatus = (name: any, e: any) => {
    dispatch(listingOutletsByhotels({hotelName: e[0]?.value,page: currentPage,limit: 50}))
    dispatch(listingHotels({name: e}))  
 }

 const searchByName = (keyName: any,e: any) => {
  
  if(e !== ""){
    dispatch(listingHotels({name: e}))  
  }  
  else{
    if(name !== "all"){
      dispatch(listingOutletsByhotels({name: name}))
    }
    else{
      dispatch(listingOutletsByhotels({page: currentPage,limit: 50}))
    }
  }
}

const toggleResponsiveModal = () => {
  query.delete("delete")
  query.delete("edit")
  query.delete('add')
  reset({})
  dispatch(listingHotels({page: currentPage,limit: 50}))  
  navigate(-1);
  setResponsiveModal(!responsiveModal);
  };

const toggleResponsiveModalForUser = () => {
query.delete("delete")
query.delete("edit")
query.delete('add')
// dispatch(listingOutlets({page: page,limit:limit}))  
navigate(-1);
setResponsiveModalForUser(!responsiveModalForUser);
};
  
const deleteEvent = () => {
  if(tab === "0"){
    dispatch(deleteHotel(deleteParam))
    query.delete("delete");
    setResponsiveModal(!responsiveModal);
    navigate('/hotel-outlet/list/1/50/all/0');
    dispatch(listingOutletsLoading())
    dispatch(listingHotels({page: currentPage,limit: 50}))
  }
  else{
      console.log('the eveme inn delete', deleteParam)
        dispatch(deleteOutlets(deleteParam))
        query.delete("delete");
        setResponsiveModal(!responsiveModal);
        navigate('/hotel-outlet/list/1/50/all/1');
        dispatch(listingOutletsLoading())
        dispatch(listingOutlets(OutletsActionTypes.LIST_OUTLETS,{page: currentPage,limit: 50}))
  }
} 
const OpenHotelAddingModal= () => {
  // navigate(`add`);
  localStorage.setItem('typeOfActionHotel', "add")
  setResponsiveModal(!responsiveModal)
}
const OpenOutletAddingModal= () => {
  navigate(`/hotel-outlet/list/add`);
  // setResponsiveModal(!responsiveModal)
}
const addEventDetails = (e: any) => {
  
  console.log('te er',e,localStorage.getItem("typeOfActionHotel") , {_id:editParam,...e})
  hotelImages?.map((img:any) => {
    img?.preview?.startsWith("blob:") &&
    formData.append('hotelLogo',img)
  })
  formData.append('data',JSON.stringify({_id:selectedHotels._id,...e}))
  if(localStorage.getItem("typeOfActionHotel") === "edit"){
    
    dispatch(updateSelectedHotels(formData))
    // debugger;
    query.delete("edit");
    setResponsiveModal(!responsiveModal);
    reset({
      hotelName: "",
      SOAmail: "",
      abbreviation: "",
      adminNumber:"",
      // longitude: selectedHotels?.hotelName,
      // latitude: selectedHotels?.hotelName,
      zipCode: "",

    })
    setHotelImages([])
    // navigate(-1);
    dispatch(listingHotels({page: currentPage,limit: 50}))
  }
  else if(localStorage.getItem("typeOfActionHotel") === "add"){
    dispatch(createHotels(formData))
    query.delete("add");
    setResponsiveModal(!responsiveModal);
    reset({
      hotelName: "",
      SOAmail: "",
      abbreviation: "",
      adminNumber:"",
      // longitude: selectedHotels?.hotelName,
      // latitude: selectedHotels?.hotelName,
      zipCode: "",

    })
    setHotelImages([])
    // navigate(-1);
    dispatch(listingHotels({page: currentPage,limit: 50}))
  }
}
const [currentPage, setCurrentPage] = useState(page);
const uploadFiles = (files: any, name: any) => {
  console.log('te files', files)
  // formData.append(name, files[0])
  setHotelImages((prevData) => files)
     
  } 
  const removeFiles = (files: any) => {
    console.log('te files', files)
    setHotelImages((prevData) => files)
  };

  const FilesUploadLogicHotelLogo = useMemo(() => {
    // if (hotelImages.length > 0) {
    //   setImageLoading(false);
    // }
    console.log("the image sin memo ", hotelImages)
    return (
      <FileUpload
        showPreview={true}
        name={"hotelLogo"}
        files={hotelImages}
        uploadFiles={uploadFiles}
        removeFiles={removeFiles}
      />
    );
  }, [hotelImages]);

const HotelCard = (hotelsList:any) => 
  
   {
    return (
      <>
      {hotelsloading ? <Loader/> : ( <Card>
    <Card.Body>
      <Table
        columns={columns}
        data={UsersTableColumns(hotelsList)}
        pageSize={limit}
        isSortable={true}
      />
        <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={(hotelsList).totalPage}
        pageSize={limit}
        onPageChange={(page: any) => {
          setCurrentPage(page)
          navigate(`/hotel-outlet/list/${page}/${limit}/${name}/0`);
          dispatch(listingHotels({page: 1,limit: 10000}))
    }}/>
    </Card.Body>
  </Card>)}
      </>
    )
   }
  
  // useEffect(()=>{
  //   OutletCard(outletsList)
  // },[outletsList])

const OutletCard = (outletsList:any) => {
  console.log("after hchan", outletsList)
  return (
    <Card>
    <Card.Body>
    {(outletsList?.data?.length > 0) ? ( <><Table
          columns={OutletColumns}
          data={OutletTableColumns(outletsList)}
          isSortable={true} /><Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={(outletsList).totalPage}
            pageSize={limit}
            onPageChange={(page: any) => {
              setCurrentPage(page);
              navigate(`/hotel-outlet/list/${page}/${limit}/${name}/1`);
              dispatch(listingOutlets(OutletsActionTypes.LIST_OUTLETS, { page: page, limit: 50 }));
            } } /></>) : <Loader/>}
    </Card.Body>
  </Card>
  )
}

const hotelOptionsListForSearch = (data: any) => {
  let hotel: any = [];
  data?.data?.map((list: any, index: any) => hotel.push({id: index, value: list._id, label: list.hotelName?  list.hotelName : " "}))
  return hotel;
  }

console.log("te users lst" , outletsUsersList)

  return(
        <div>
            <h1>Hotel and Outlet</h1>
      <Tab.Container id="left-tabs-example" defaultActiveKey={tab === "0" ? "first" : "second" } onSelect={(e) =>  navigate(`/hotel-outlet/list/${page}/${limit}/all/${e === "first" ?  "0" : "1"}`)}>
      <Nav
        as="ul"
        variant="tabs"
        className="nav nav-tabs nav-bordered"
      >
        <Nav.Item as="li" >
          <Nav.Link eventKey="first" className="cursor-pointer"  style={{cursor:'pointer'}}>
            Hotels
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link eventKey="second" className="cursor-pointer"  style={{cursor:'pointer'}}>
          Outlets
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Tab.Content>
      <Tab.Pane eventKey="first">
      <CommonSearchBar
          name={name}
          searchTitle={"Search hotels..."}
          search={(e:any) => searchHotels(e)}
          filterValueChanged={filterValueChangeByUsersStatus}
          filters={
            []
          }
          openAddModal={OpenHotelAddingModal}
          />
           <Row>
        <Col>
<Modal show={responsiveModal} onHide={toggleResponsiveModal}   scrollable={true}>
      <Modal.Body className="p-2">
     {deleteParam ?
      (
      <>
       <div>
     {(tab === "0") ?  <h3>Are you sure you want to delete the selected  hotel?</h3> : <h3>Are you sure you want to delete the selected  outlet?</h3> }
      <div className="text-end">
      <Button
      variant="success"
      type="submit"
      className="waves-effect waves-light me-1"
      onClick={() => deleteEvent()}
      >
      Save
      </Button>
      <Button
      variant="danger"
      className="waves-effect waves-light"
      onClick={toggleResponsiveModal}
      >
      Close
      </Button>
      </div>
        </div>
      </>
     )
     :

         (
        <>
        {editParam ? (<h1>Edit Hotels</h1>) : (<h1>Add Hotels</h1>)}
        {/* {addParam && } */}
          <form onSubmit={handleSubmit(addEventDetails)} >
              <FormInput
              label="Name"
              type="text"
              name="hotelName"
              placeholder="Enter name"
              required
              containerClass={"mb-3"}
              register={register}
              />
              <FormInput
              label="SOA Email"
              type="email"
              name="SOAmail"
              required
              register={register}
              placeholder="Enter the SOA Email"
              containerClass={"mb-3"}
              />
              <FormInput
              label="Abbreviation"
              type="text"
              name="abbreviation"
              required
              register={register}
              placeholder="Enter the abbreviaton"
              containerClass={"mb-3"}
              />
              <FormInput
                label="Admin Number"
                type="text"
                name="adminNumber"
                required
                containerClass={"mb-3"}
                register={register}
                placeholder="Enter the admin number"
                      />
                      <FormInput
                label="Zip Code"
                type="number"
                required
                name="zipCode"
                register={register}
                placeholder="Enter the Zip Code"
                containerClass={"mb-3"}
                />
              {/* <FormInput
                label="Longitude"
                type="text"
                required
                name="longitude"
                register={register}
                placeholder="Enter the longitude"
                containerClass={"mb-3"}
                /> */}
              {/* <FormInput
                label="Latitude"
                type="text"
                required
                name="latitude"
                register={register}
                placeholder="Enter the latitude"
                containerClass={"mb-3"}
                /> */}
              {FilesUploadLogicHotelLogo}
                {/* <FileUpload removeFiles={removeFiles}  name={"hotelLogo"} multiple={1}  files={hotelImages} uploadFiles={uploadFiles}/> */}
                  

              <div className="text-end">
              <Button
              variant="success"
              type="submit"
              className="waves-effect waves-light me-1"
              >
              Save
              </Button>
              <Button
              variant="danger"
              className="waves-effect waves-light"
              onClick={ () => toggleResponsiveModal()
                }   
              >
              Close
              </Button>
              </div>
              </form>
        </>
            
            )}
    </Modal.Body>
</Modal>

         {HotelCard(hotelsList)}
        </Col>
      </Row>
      </Tab.Pane>
      <Tab.Pane eventKey="second">
      <CommonSearchBar
          name={name}
          searchTitle={"Search outlets..."}
          search={(e:any) => searchOutlets(e)}
          searchByName={searchByName}
          filterValueChanged={filterValueChangeByUsersStatus}
          filters={
            [
              {label: "Hotels",
                name: "hotels",
                placeholder:"Please select a hotel",
                options:hotelOptionsListForSearch(hotelsList),
                isSelect: true
                }
            ]
          }
          openAddModal={OpenOutletAddingModal}
          />
           <Row>
        <Col>
      {/* <Modal show={responsiveModal} onHide={toggleResponsiveModal}>
      <Modal.Body className="p-2">
     {deleteParam && (
      <>
       <div>
      <h3>Are you sure you want to delete the selected Outlets?</h3>
      <div className="text-end">
      <Button
      variant="success"
      type="submit"
      className="waves-effect waves-light me-1"
      onClick={() => deleteEvent()}
      >
      Save
      </Button>
      <Button
      variant="danger"
      className="waves-effect waves-light"
      onClick={toggleResponsiveModal}
      >
      Close
      </Button>
      </div>
        </div>
      </>
     )}


    </Modal.Body>
      </Modal> */}
         {OutletCard(outletsList)}
        </Col>
      </Row>
      </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
    <Modal show={responsiveModalForUser} onHide={toggleResponsiveModalForUser}>
    <div style={{margin: '2%'}}>
    <div>
      <h3>
      Users List
      </h3>
    </div>
    <Table
        columns={  [
          {
            Header: "Full Name",
            accessor: "fullname",
            sort:false,
          },
          {
            Header: "User Name",
            accessor: "username",
            sort: false
        },
        {
          Header: "Outlet Name",
          accessor: "outletName",
          sort: false
      }
      ]}
        data={OutletUserTableColumns(outletsUsersList)}
        pageSize={limit}
        isSortable={true}
      />
     
    </div>
    
    </Modal>
        </div>
    )
}

export default HotelOutLetList;